var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "", "grid-list-lg": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _vm._t("header"),
          _vm._l(_vm.computedSeekerPorfiles, function(seekerProfile) {
            return _c(
              "v-flex",
              {
                key: seekerProfile.id,
                attrs: {
                  xs12: "",
                  md6: _vm.small,
                  lg4: _vm.small,
                  sm6: !_vm.small,
                  md4: !_vm.small,
                  lg3: !_vm.small
                }
              },
              [
                _c(
                  "seeker-card",
                  {
                    attrs: {
                      "seeker-profile": seekerProfile,
                      small: _vm.small,
                      dark: _vm.dark,
                      "no-video": _vm.noVideo
                    },
                    on: {
                      "card-click": function($event) {
                        return _vm.cardClick(seekerProfile)
                      }
                    }
                  },
                  [
                    _vm._t("card-actions", null, {
                      slot: "card-actions",
                      xslotScope: "props",
                      seekerProfile: seekerProfile
                    })
                  ],
                  2
                )
              ],
              1
            )
          }),
          _vm._t("footer")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }