


































import Vue from "vue";
import seekerCard from "@/components/seekers/seekerCard.vue";
import * as models from "@/models";

export default {
  name: "seekerCardsList",
  props: {
    seekerProfiles: {
      type: Array,
      default: () => []
    },
    small: {
      type: Boolean, //Object as () => Boolean | String,
      required: false,
      default: false
    },
    dark: {
      type: Boolean, //Object as () => Boolean | String,
      required: false,
      default: false
    },
    noVideo: { required: false, type: Boolean, default: false }
  },
  data: () => ({}),
  created: function() {},
  methods: {
    cardClick: function(sp: models.seekerProfile) {
      this.$emit("card-click", sp);
    }
  },
  computed: {
    computedSeekerPorfiles: function(): models.seekerProfile[] {
      return this.seekerProfiles;
    },
    smallMode: function(): boolean {
      return !(this.small === undefined || this.small == false);
    },
    darkMode: function(): boolean {
      return !(this.dark === undefined || this.dark == false);
    }
  },
  components: { seekerCard: seekerCard }
};
