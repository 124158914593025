var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.seekerProfile
    ? _c(
        "v-card",
        {
          staticClass: "elevation-11 seeker-card",
          class: [
            { "small-mode": _vm.smallMode },
            _vm.darkMode ? "white--text" : "primary--text",
            _vm.darkMode ? "primary" : "white"
          ],
          attrs: {
            dark: _vm.darkMode,
            "nop-style":
              "{'background-image':`url(${video.vidThumbUriComputed})`}"
          }
        },
        [
          !_vm.smallMode
            ? _c(
                "video-player",
                {
                  attrs: {
                    "no-video": _vm.noVideo,
                    "poster-url": _vm.video.vidThumbUriComputed,
                    "video-url": _vm.video.vidUri
                  },
                  on: { click: _vm.cardClick }
                },
                [
                  _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "0",
                          color: "white",
                          padding: "15px",
                          left: "0",
                          right: "0",
                          "padding-bottom": "25px",
                          "background-image":
                            "linear-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.7))"
                        }
                      },
                      [
                        _c("h2", [_vm._v(_vm._s(_vm.seekerProfile.headline))]),
                        _c(
                          "h3",
                          { staticClass: "accent--text xxtext--darken-2" },
                          [_vm._v(_vm._s(_vm.seekerProfile.industry.title))]
                        ),
                        _c("h3", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.seekerProfile.firstname) +
                              "\n            " +
                              _vm._s(_vm.seekerProfile.lastname) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "v-layout",
            {
              staticClass: "seeker-card-main-content",
              attrs: { row: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.cardClick($event)
                }
              }
            },
            [
              _vm.smallMode
                ? _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("v-card-title", { attrs: { "primary-title": "" } }, [
                        _c("div", [
                          _c("h2", [
                            _vm._v(_vm._s(_vm.seekerProfile.headline))
                          ]),
                          _c(
                            "h3",
                            { staticClass: "accent--text text--darken-2" },
                            [_vm._v(_vm._s(_vm.seekerProfile.industry.title))]
                          ),
                          _c("h3", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.seekerProfile.firstname) +
                                "\n              " +
                                _vm._s(_vm.seekerProfile.lastname) +
                                "\n            "
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.smallMode
                ? _c(
                    "div",
                    { staticClass: "small-mode-thumb" },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "elevation-7",
                          staticStyle: {
                            "max-width": "200px",
                            "max-height": "200px"
                          },
                          attrs: {
                            tile: "",
                            size: _vm.windowInnerWidth / 3,
                            color: "grey lighten-4"
                          }
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.video.vidThumbUriComputed,
                              xwidth: "windowInnerWidth/4",
                              coverx: "",
                              "max-width": "200px",
                              "max-height": "200px",
                              xclass: "elevation-7 xside-thumb",
                              "xclick.stop": "cardClick"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._t(
            "card-actions",
            [
              _c(
                "v-card-actions",
                { staticClass: "pa-3" },
                [
                  _vm._v("\n        Rate this album\n        "),
                  _c("v-spacer"),
                  _c("v-icon", [_vm._v("star_border")]),
                  _c("v-icon", [_vm._v("star_border")]),
                  _c("v-icon", [_vm._v("star_border")]),
                  _c("v-icon", [_vm._v("star_border")]),
                  _c("v-icon", [_vm._v("star_border")])
                ],
                1
              )
            ],
            { item: _vm.seekerProfile }
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }