



































































































































import Vue from "vue";
import * as models from "@/models";
import Moment from "moment";
import videoPlayer from "@/components/videoPlayer.vue";

export default {
  name: "seekerCard",
  // props: ["jobPost"],
  props: {
    seekerProfile: {
      type: Object,
      default: <models.seekerProfile>null,
      required: true
    },
    small: {
      type: Boolean, //Object as () => Boolean | String,
      required: false,
      default: false
    },
    dark: {
      type: Boolean, //Object as () => Boolean | String,
      required: false,
      default: false
    },
    noVideo: { required: false, type: Boolean, default: false }
  },
  data: () => ({}),
  created: function() {
    Moment.defaultFormat = "DD.MM.YYYY HH:mm";
  },
  methods: {
    cardClick: function() {
      this.$emit("card-click", this.seekerProfile);
    }
  },
  computed: {
    windowInnerWidth: function(): number {
      return window.innerWidth;
    },
    seekerVideo: function(): models.seekerVideo {
      if (
        this.seekerProfile.seekerVideos &&
        this.seekerProfile.seekerVideos.length > 0
      )
        return this.seekerProfile.seekerVideos[0];
      else return null;
    },
    video: function(): models.video {
      if (this.seekerVideo) return this.seekerVideo.video;
      else return null;
    },
    smallMode: function(): boolean {
      return !(this.small === undefined || this.small == false);
    },
    darkMode: function(): boolean {
      return !(this.dark === undefined || this.dark == false);
    }
  },
  watch: {},
  components: { videoPlayer }
};
